<template>
  <v-row no-gutters class="patient-page px-1 mb-4" justify="center">
    <v-app-bar app color="navColor">
      <v-row justify="center">
        <v-col sm="10" md="9" lg="8" xl="6" xs="12">
          <v-toolbar color="transparent" dark flat>
            <img
              v-if="appTheme"
              style="max-height: 30px; object-fit: contain;"
              src="https://firebasestorage.googleapis.com/v0/b/novo00.appspot.com/o/icons%2FArrowhealth-logo-RGB.png?alt=media&token=4513496c-57e3-4855-9c22-15abefd8a708"
            />
            <v-spacer></v-spacer>
            <v-toolbar-title v-if="!isMobile">Practice Revenue Calculator</v-toolbar-title>
            <v-toolbar-title v-else>Calculator</v-toolbar-title>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-col sm="10" md="9" lg="8" xl="6" xs="12">
      <v-row>
        <v-col cols="12">
          <v-row no-gutters align="center" justify="space-between">
            <v-card-title class="paragraph-1 font-weight-light">
              <span v-if="!isMobile">Average Patients per Provider</span>
              <span v-else>Patients per Provider</span>
            </v-card-title>
            <v-card-title class="paragraph-1 font-weight-bold mt-0 pt-0">
              {{ numberOfPatientsPerProvider }}
            </v-card-title>
          </v-row>
          <v-row class="pt-0 px-2" no-gutters>
            <v-slider
              v-model="numberOfPatientsPerProvider"
              thumb-label
              step="100"
              ticks="always"
              max="3000"
              hide-details
            />
          </v-row>
          <v-card-text style="opacity: 0.7" class="mt-0 pt-0 pb-0 mb-0"
            >The typical panel size for a primary care physician ranges from from 1200 to 1900 patients.
          </v-card-text>
        </v-col>
        <v-col cols="12">
          <v-row no-gutters align="center" justify="space-between">
            <v-card-title class="paragraph-1 font-weight-light mt-0 pt-0">
              Number of Physicians
            </v-card-title>
            <v-card-title class="paragraph-1 font-weight-bold mt-0 pt-0">
              {{ numberOfProviders }}
            </v-card-title>
          </v-row>
          <v-row class="pt-0 px-2" no-gutters>
            <v-slider v-model="numberOfProviders" thumb-label step="1" ticks="always" max="15" min="1" hide-details />
          </v-row>
          <v-card-text style="opacity: 0.7" class="mt-0 pt-0"
            >The number of physicians expected to participate in Remote Patient Monitoring. The above number will be
            multiplied by participating physicians to calculate anticipated eligible population.
          </v-card-text>
        </v-col>
        <!--medicare sliders-->
        <v-col :cols="smallSliderWidth">
          <v-row no-gutters align="center" justify="space-between">
            <v-card-title class="paragraph-1 font-weight-light mt-0 pt-0">
              <span v-if="!isMobile">Medicare Beneficiaries Percentage</span>
              <span v-else>Medicare Beneficiaries</span>
            </v-card-title>
            <v-card-title class="paragraph-1 font-weight-bold mt-0 pt-0">
              {{ medicarePatientPercentage }}%
            </v-card-title>
          </v-row>
          <v-row class="pt-0 px-2 pb-3" no-gutters>
            <v-slider v-model="medicarePatientPercentage" thumb-label max="100" hide-details />
          </v-row>
          <v-card-text style="opacity: 0.7" class="mt-0 pt-0"
            >Percentage of patient population that are Medicare beneficiaries.
          </v-card-text>
        </v-col>

        <v-col v-if="!isMobile" :cols="smallSliderWidth">
          <v-row no-gutters align="center" justify="space-between">
            <v-card-title class="paragraph-1 font-weight-light mt-0 pt-0 pb-0 mt-0">
              Total Number of Medicare Patients
            </v-card-title>
          </v-row>
          <v-row class="pt-2 px-2 pb-3 px-4" no-gutters>
            <v-text-field
              readonly
              outlined
              dense
              hide-details
              :value="formatNumberWithCommas(totalMedicarePatients) + ' Patients'"
              class="paragraph-1 font-weight-bold mt-0 pt-0 pb-0 mt-0"
            >
            </v-text-field>
          </v-row>
          <v-card-text style="opacity: 0.7" class="mt-0 pt-0"
            >The total number of Medicare eligible patients in the patient population.
          </v-card-text>
        </v-col>
        <!-- chronic conditions sliders-->
        <v-col :cols="smallSliderWidth">
          <v-row no-gutters align="center" justify="space-between">
            <v-card-title class="paragraph-1 font-weight-light mt-0 pt-0">
              <span v-if="!isMobile">
                RPM Patient Candidates
              </span>
              <span v-else>
                RPM Candidates
              </span>
            </v-card-title>
            <v-card-title class="paragraph-1 font-weight-bold mt-0 pt-0"> {{ rpmNeededPercentage }}% </v-card-title>
          </v-row>
          <v-row class="pt-0 px-2 pb-3" no-gutters>
            <v-slider v-model="rpmNeededPercentage" thumb-label max="100" hide-details />
          </v-row>
          <v-card-text style="opacity: 0.7" class="mt-0 pt-0">
            Patients with an appropriate medical condition that would benefit from Remote Patient Monitoring as a
            percentage of total patient population. Common conditions include Diabetes, Obesity, Hypertension, and CHF.
            Providers may recommend RPM services for any patient who may benefit from the service.
          </v-card-text>
        </v-col>

        <v-col v-if="!isMobile" :cols="smallSliderWidth">
          <v-row no-gutters align="center" justify="space-between">
            <v-card-title class="paragraph-1 font-weight-light mt-0 pt-0 pb-0 mt-0">
              Total Number of Eligible Patients
            </v-card-title>
          </v-row>
          <v-row class="pt-2 px-2 pb-3 px-4" no-gutters>
            <v-text-field
              readonly
              outlined
              dense
              hide-details
              :value="formatNumberWithCommas(eligiblePatients) + ' Patients'"
              class="paragraph-1 font-weight-bold mt-0 pt-0 pb-0 mt-0"
            >
            </v-text-field>
          </v-row>
          <v-card-text style="opacity: 0.7" class="mt-0 pt-0"
            >The total number of medicare eligible patients in the patient population.
          </v-card-text>
        </v-col>

        <!-- chronic conditions sliders-->
        <v-col :cols="smallSliderWidth">
          <v-row no-gutters align="center" justify="space-between">
            <v-card-title class="paragraph-1 font-weight-light mt-0 pt-0">
              Patient Opt-In Rate
            </v-card-title>
            <v-card-title class="paragraph-1 font-weight-bold mt-0 pt-0"> {{ optInRate }}% </v-card-title>
          </v-row>
          <v-row class="pt-0 px-2 pb-3" no-gutters>
            <v-slider v-model="optInRate" thumb-label max="100" hide-details />
          </v-row>
          <v-card-text style="opacity: 0.7" class="mt-0 pt-0">
            Eligible patients opt in to participate in Remote Patient Monitoring. This opt-in process can be conducted
            in-office or remotely, by clinic staff, or by the Novo RPM team.
          </v-card-text>
        </v-col>

        <v-col v-if="!isMobile" :cols="smallSliderWidth">
          <v-row no-gutters align="center" justify="space-between">
            <v-card-title class="paragraph-1 font-weight-light mt-0 pt-0 pb-0 mt-0">
              Patients to Enroll
            </v-card-title>
          </v-row>
          <v-row class="pt-2 px-2 pb-3 px-4" no-gutters>
            <v-text-field
              readonly
              outlined
              dense
              hide-details
              :value="formatNumberWithCommas(expectedEnrollments) + ' Patients'"
              class="paragraph-1 font-weight-bold mt-0 pt-0 pb-0 mt-0"
            >
            </v-text-field>
          </v-row>
          <v-card-text style="opacity: 0.7" class="mt-0 pt-0"
            >Estimated number of eligible patients to be enrolled in Remote Patient Monitoring.
          </v-card-text>
        </v-col>
      </v-row>

      <v-divider class="mb-4" />

      <v-card class="mb-4 pb-2" flat :outlined="!isMobile" :color="cardColor">
        <v-row justify="space-between" no-gutters>
          <v-card-title class="paragraph-1 font-weight-light">
            <span v-if="!isMobile">Active Management (First 20 Minutes)</span>
            <span v-else>Active Management</span>
          </v-card-title>
          <v-card-title class="paragraph-1 font-weight-light"> {{ activeMonitoringValue }}% </v-card-title>
        </v-row>
        <v-row class="pt-0 px-0" no-gutters>
          <v-slider v-model="activeMonitoringValue" thumb-label max="100" hide-details class="mx-2" />
        </v-row>
        <v-card-text class="pt-0 mt-0">
          Select the percentage of enrolled patients expected to receive 20 minutes or more of active monitoring and
          interactive communication each month from qualified healthcare professionals. This includes time reviewing the
          chart, texting, calling, or otherwise managing care.
        </v-card-text>
      </v-card>

      <v-card class="mb-4 pb-2" flat :outlined="!isMobile" :color="cardColor">
        <v-row justify="space-between" no-gutters>
          <v-card-title class="paragraph-1 font-weight-light">
            <span v-if="!isMobile">Additional Active Management (Next 20 Minutes)</span>
            <span v-else>Additional Active Management</span>
          </v-card-title>
          <v-card-title class="paragraph-1 font-weight-light"> {{ additionalMonitoringValue }}% </v-card-title>
        </v-row>
        <v-row class="pt-0 px-0" no-gutters>
          <v-slider v-model="additionalMonitoringValue" thumb-label max="100" hide-details class="mx-2" />
        </v-row>
        <v-card-text class="pt-0 mt-0">
          Select the percentage of the practice patient population expected to receive an additional 20 minutes or more
          of active monitoring and communication each month.
        </v-card-text>
      </v-card>

      <v-card class="mb-4 pb-2" flat :outlined="!isMobile" :color="cardColor">
        <v-row justify="space-between" no-gutters>
          <v-card-title class="paragraph-1 font-weight-light">
            <span v-if="!isMobile">Co-Pay Collection Rate</span>
            <span v-else>Co-Pay Collection</span>
          </v-card-title>
          <v-card-title class="paragraph-1 font-weight-light"> {{ coPayCollectionRate }}% </v-card-title>
        </v-row>
        <v-row class="pt-0 px-0" no-gutters>
          <v-slider v-model="coPayCollectionRate" thumb-label max="100" hide-details class="mx-2" />
        </v-row>
        <v-card-text class="pt-0 mt-0">
          RPM services, like other Medicare Part B services, are subject to a 20% beneficiary copay. This co-pay is
          generally not waivable, but it may be covered if a patient has supplemental coverage or Medicaid. CMS requires
          practices to attempt to collect a 20% co-pay for services rendered.
        </v-card-text>
      </v-card>

      <v-card style="overflow: hidden" class="mb-1" :outlined="!isMobile" flat>
        <v-card-title class="pb-0">
          <span v-if="!isMobile"> Estimated First Year Gross Practice Revenue</span>
          <span v-else>First Year Revenue</span>
        </v-card-title>
        <v-card-text class="pt-2 pb-3">
          RPM services, like other Medicare Part B services, are subject to a 20% beneficiary copay. This co-pay is
          generally not waivable, but it may be covered if a patient has supplemental coverage or Medicaid. Private
          payers establish their own copay policies and may choose not to require a copay. The values below have been
          adjusted to not include the the 20% medicare beneficiary copay.
        </v-card-text>

        <v-row no-gutters justify="space-between">
          <v-col class="flex-grow-1 flex-shrink-1">
            <v-list-item v-if="!isMobile">
              <v-list-item-content>
                <v-list-item-title class="paragraph-1 font-weight-bold">CPT 99453</v-list-item-title>
                <v-list-item-subtitle
                  >Initial set-up and patient education. One-time-reimbursement.</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-card-title v-else class="paragraph-1 font-weight-light justify-start">
              CPT 99453
            </v-card-title>
          </v-col>
          <v-col class="flex-grow-1 flex-shrink-1">
            <v-card-title class="paragraph-1 font-weight-bold justify-end">
              {{ formatStringToCurrency(initialSetupCopayAdjustedRevenue) }}
            </v-card-title>
          </v-col>
        </v-row>
        <v-divider />

        <v-row no-gutters justify="space-between">
          <v-col class="flex-grow-1 flex-shrink-1">
            <v-list-item v-if="!isMobile">
              <v-list-item-content>
                <v-list-item-title class="paragraph-1 font-weight-bold">CPT 99454</v-list-item-title>
                <v-list-item-subtitle>Device transmission reimbursement.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-title v-else class="paragraph-1 font-weight-light justify-start">
              CPT 99454
            </v-card-title>
          </v-col>
          <v-col class="flex-grow-1 flex-shrink-1">
            <v-card-title class="paragraph-1 font-weight-bold justify-end">
              {{ formatStringToCurrency(transmissionCopayAdjustedRevenue) }}
            </v-card-title>
          </v-col>
        </v-row>
        <v-divider />

        <v-row no-gutters justify="space-between">
          <v-col class="flex-grow-1 flex-shrink-1">
            <v-list-item v-if="!isMobile">
              <v-list-item-content>
                <v-list-item-title class="paragraph-1 font-weight-bold">CPT 99457</v-list-item-title>
                <v-list-item-subtitle>20 minutes of patient monitoring and communication.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-title v-else class="paragraph-1 font-weight-light justify-start">
              CPT 99457
            </v-card-title>
          </v-col>
          <v-col class="flex-grow-1 flex-shrink-1">
            <v-card-title class="paragraph-1 font-weight-bold justify-end">
              {{ formatStringToCurrency(activeMonitoringCopayAdjustedRevenue) }}
            </v-card-title>
          </v-col>
        </v-row>
        <v-divider />

        <v-row no-gutters justify="space-between">
          <v-col class="flex-grow-1 flex-shrink-1">
            <v-list-item v-if="!isMobile">
              <v-list-item-content>
                <v-list-item-title class="paragraph-1 font-weight-bold">CPT 99458</v-list-item-title>
                <v-list-item-subtitle
                  >Additional 20 minutes of patient monitoring and communication.</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-card-title v-else class="paragraph-1 font-weight-light justify-start">
              CPT 99458
            </v-card-title>
          </v-col>
          <v-col class="flex-grow-1 flex-shrink-1">
            <v-card-title class="paragraph-1 font-weight-bold justify-end">
              {{ formatStringToCurrency(additionalMonitoringCopayAdjustedRevenue) }}
            </v-card-title>
          </v-col>
        </v-row>
        <v-divider />

        <v-card color="#f5f5f5" flat tile>
          <v-row no-gutters justify="space-between">
            <v-col class="flex-grow-1 flex-shrink-1">
              <v-list-item v-if="!isMobile">
                <v-list-item-content>
                  <v-list-item-title class="paragraph-1 font-weight-bold">Patient Co-Pay</v-list-item-title>
                  <v-list-item-subtitle
                    >Based on co-pay collection rates and Medicare Part B beneficiary
                    responsibility.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-card-title v-else class="paragraph-1 font-weight-light justify-start">
                Patient Co-Pay
              </v-card-title>
            </v-col>
            <v-col class="flex-grow-1 flex-shrink-1">
              <v-card-title class="paragraph-1 font-weight-bold justify-end" style="color:gray">
                {{ formatStringToCurrency(coPayRevenue) }}
              </v-card-title>
            </v-col>
          </v-row>
        </v-card>

        <v-card tile flat dark color="primary">
          <v-row no-gutters justify="space-between">
            <v-col class="flex-grow-1 flex-shrink-1">
              <v-card-title class="paragraph-1 font-weight-light justify-start">
                Gross Revenue
              </v-card-title>
            </v-col>
            <v-col class="flex-grow-1 flex-shrink-1">
              <v-card-title class="paragraph-1 font-weight-bold justify-end">
                {{ formatStringToCurrency(practiceRevenue) }}
              </v-card-title>
            </v-col>
          </v-row>
        </v-card>
      </v-card>

      <v-fade-transition>
        <v-footer v-show="showFooter" color="transparent" app>
          <v-row align="center" justify="center">
            <v-col sm="10" md="9" lg="8" xl="6" xs="12">
              <v-card class="pa-3 px-5" color="primary" dark>
                <v-row class="pa-0" no-gutters justify="space-between">
                  <span
                    v-if="!isMobile"
                    class="paragraph-1 font-weight-light justify-end pa-0 ma-0"
                    style="font-size: 20px;"
                  >
                    Estimated First Year Gross Practice Revenue
                  </span>
                  <span v-else class="paragraph-1 font-weight-light justify-end pa-0 ma-0" style="font-size: 20px;">
                    Yr 1 Gross Revenue
                  </span>
                  <span class="paragraph-1 font-weight-bold justify-end pa-0 ma-0" style="font-size: 20px;">
                    {{ formatStringToCurrency(practiceRevenue) }}
                  </span>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-footer>
      </v-fade-transition>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [HelperMixin],
  name: 'loginpage',
  components: {},
  data() {
    return {
      numberOfPatientsPerProvider: 1800,
      numberOfProviders: 3,
      medicarePatientPercentage: 30,
      rpmNeededPercentage: 75,
      optInRate: 65,
      coPayCollectionRate: 0,
      activeMonitoringValue: 70,
      initialSetupReimbursement: 21,
      transmissionReimbursement: 69,
      activeMonitoringReimbursement: 53,
      additionalMonitoringReimbursement: 43,
      novoPerPatientServiceFee: 40,
      additionalMonitoringValue: 30,
      showFooter: true,
    }
  },
  watch: {
    activeMonitoringValue(val) {
      if (val < this.additionalMonitoringValue) {
        this.additionalMonitoringValue = val
      }
    },
    additionalMonitoringValue(val) {
      if (val > this.activeMonitoringValue) {
        this.activeMonitoringValue = val
      }
    },
  },
  computed: {
    ...mapState('auth', ['appTheme']),
    totalMedicarePatients() {
      return Math.floor(
        (this.numberOfPatientsPerProvider * this.numberOfProviders * this.medicarePatientPercentage) / 100
      )
    },
    eligiblePatients() {
      return Math.floor((this.totalMedicarePatients * this.rpmNeededPercentage) / 100)
    },
    expectedEnrollments() {
      return Math.floor((this.eligiblePatients * this.optInRate) / 100)
    },
    initialSetupTotalRevenue() {
      return this.expectedEnrollments * this.initialSetupReimbursement
    },
    transmissionTotalRevenue() {
      return this.expectedEnrollments * this.transmissionReimbursement * 12
    },
    activeMonitoringTotalRevenue() {
      return (
        Math.floor((this.expectedEnrollments * this.activeMonitoringValue) / 100) *
        this.activeMonitoringReimbursement *
        12
      )
    },
    additionalMonitoringTotalRevenue() {
      return (
        Math.floor((this.expectedEnrollments * this.additionalMonitoringValue) / 100) *
        this.additionalMonitoringReimbursement *
        12
      )
    },

    initialSetupCopayAdjustedRevenue() {
      return this.initialSetupTotalRevenue * 0.8
    },
    transmissionCopayAdjustedRevenue() {
      return this.transmissionTotalRevenue * 0.8
    },
    activeMonitoringCopayAdjustedRevenue() {
      return this.activeMonitoringTotalRevenue * 0.8
    },
    additionalMonitoringCopayAdjustedRevenue() {
      return this.additionalMonitoringTotalRevenue * 0.8
    },

    smallSliderWidth() {
      if (this.isMobile) {
        return '12'
      }
      return '6'
    },
    coPayRevenue() {
      return (
        ((this.initialSetupTotalRevenue +
          this.transmissionTotalRevenue +
          this.activeMonitoringTotalRevenue +
          this.additionalMonitoringTotalRevenue) *
          0.2 *
          this.coPayCollectionRate) /
        100
      )
    },
    practiceRevenue() {
      return (
        this.initialSetupCopayAdjustedRevenue +
        this.transmissionCopayAdjustedRevenue +
        this.activeMonitoringCopayAdjustedRevenue +
        this.additionalMonitoringCopayAdjustedRevenue +
        this.coPayRevenue
      )
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    cardColor() {
      if (this.isMobile) {
        return 'white'
      }
      return '#f5f5f5'
    },
  },
  methods: {
    ...mapActions('auth', ['signIn', 'signOut']),
    ...mapActions('provider', ['getProviderAuth']),
    formatStringToCurrency(string) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      return formatter.format(string)
    },
    formatNumberWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    parseURLParameters() {
      const search = this.$route.query
      if (search.pain) {
        this.showPain = true
      }

      if (search.covid) {
        this.showCovid = true
      }
    },
    switchToRegister() {
      this.$router.push({ name: 'Register', query: this.$route.query })
    },
    resetPassword() {
      this.$router.push({ name: 'PasswordReset' })
    },
    submitLogin() {
      this.loginLoading = true
      if (this.$refs.loginForm.validate()) {
        let tokenHere = null
        if (this.patientShareToken) {
          tokenHere = this.patientShareToken
        } else if (this.userGroupInviteToken) {
          tokenHere = this.userGroupInviteToken
        }
        let postActionsPayload = {
          shareID: this.shareID,
          authToken: tokenHere,
          provider: this.provider,
          affiliateCode: this.affiliateCode,
        }
        const credentials = {
          email: this.email,
          password: this.password,
          payload: postActionsPayload,
        }
        this.signIn(credentials)
          .then(() => {
            // bus.$emit('toast', { type: 'success', text: 'Logged In' })
            console.log('signed in')
          })
          .catch(error => {
            console.log(error)
            switch (error.code) {
              case 'auth/user-not-found':
                bus.$emit('toast', {
                  type: 'error',
                  text: 'User not found. Sign up instead?',
                })
                break
              case 'auth/invalid-email':
                bus.$emit('toast', { type: 'error', text: 'Invalid Email' })
                break
              case 'auth/wrong-password':
                bus.$emit('toast', { type: 'error', text: 'Invalid password' })
                break
              default:
                bus.$emit('toast', { type: 'error', text: error.code })
            }
            this.loginLoading = false
          })
      } else {
        this.loginLoading = false
      }
    },
  },
  mounted() {
    let vm = this
    window.onscroll = function() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 70) {
        vm.showFooter = false
      } else {
        vm.showFooter = true
      }
    }
  },
}
</script>
